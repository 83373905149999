import { createReducer } from '@reduxjs/toolkit';

import {
  createDigestSuccess,
  createHashSuccess,
  findTransactionSuccess,
  checkTokenSuccess,
  checkTokenFailed,
  alertToasterRequested,
  setCurrentLanguage,
} from './actions';
import { t } from '../../locale/index';
import { AlertToasterMessage, Languages } from './types';

export interface IHashState {
  isTokenValid?: boolean;
  pdfName?: string;
  jsonName?: string;
  hash?: string | undefined;
  digest?: string | undefined;
  transaction?: string | undefined;
  alertToaster?: Record<string, string>;
  language: Languages;
}

export const initialState: IHashState = {
  language: 'IT',
};

const hashReducer = createReducer(initialState, {
  [checkTokenSuccess.type]: (state) => {
    state.isTokenValid = true;
  },
  [checkTokenFailed.type]: (state) => {
    state.isTokenValid = false;
  },
  [createDigestSuccess.type]: (state, action) => {
    state.digest = action.payload.hash;
    state.pdfName = action.payload.pdfName;
  },
  [createHashSuccess.type]: (state, action) => {
    state.hash = action.payload.hash;
    state.jsonName = action.payload.jsonName;
    delete state.alertToaster;
  },
  [findTransactionSuccess.type]: (state, action) => {
    state.transaction = action.payload.transaction;
  },
  [alertToasterRequested.type]: (state, action) => {
    const { message, id } = action.payload;
    Object.assign(state, { alertToaster: { message, id } });
    delete state.hash;
    delete state.transaction;
  },
  [setCurrentLanguage.type]: (state, action) => {
    state.language = action.payload.language;
    if (state?.alertToaster) {
      Object.assign(state, {
        alertToaster: {
          message: t[state.language][state.alertToaster.id as AlertToasterMessage],
          id: state.alertToaster.id,
        },
      });
    }
  },
});

export default hashReducer;
