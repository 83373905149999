import { combineReducers } from '@reduxjs/toolkit';
import hashReducer from './selexi/reducers';

const main = { main: hashReducer };

export let rootReducer = combineReducers({
  ...main,
});

export default function createReducer(injectedReducers = {}) {
  rootReducer = combineReducers({
    ...main,
    ...injectedReducers,
  });

  return rootReducer;
}

export type RootState = ReturnType<typeof rootReducer>;
