import styled from 'styled-components';

type FlexContainerProps = {
  language: string;
};

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  align-items: center;

  button {
    width: ${({ language }) => (language === 'DE' ? '231px' : '186.73px')};
  }
`;

export const Label = styled.span`
  font-family: 'Ubuntu';
  color: #666;
`;
