import styled from 'styled-components';
import { animated } from 'react-spring';
import { Languages } from '../redux/selexi/types';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
`;

type ContentProps = {
  language: string;
};

export const Content = styled.div<ContentProps>`
  width: ${({ language }) => (language === 'DE' ? '900px' : '730px')};
  height: fit-content;
  border: 3px solid #0272bd;
  margin: 35px 35px 0 35px;
  border-radius: 10px;
  padding: 30px;
  background-color: #fff;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 65px;
`;

export const Title = styled.div`
  height: 19px;
  width: 80px;
  color: #666666;
  font-family: Ubuntu;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
  text-align: left;
`;

export const Sub = styled.div`
  height: 19px;
  width: 80px;
  color: #666666;
  font-family: Ubuntu;
  font-size: 14px;
  letter-spacing: 0;
  width: 100%;
  text-align: left;
  margin: 8px 0 15px 0;
  text-align: left;
`;

export const PageContainer = styled.div`
  margin: 50px auto;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
`;

export const FieldContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 300px;
`;

export const CopyText = styled(animated.div)`
  height: 41px;
  opacity: 0.9;
  border-radius: 4px;
  background-color: #003b4e;
  color: #fff;
  font-family: Ubuntu;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 42px;
  padding: 8px 13px;
  margin-top: 16px;
  vertical-align: middle;

  .copy {
    color: #54e7ed;
    font-family: Ubuntu;
    font-size: 14px;
    letter-spacing: 0;
    text-align: right;
    cursor: pointer;
  }
`;

export const Span = styled.span<{ language: Languages }>`
  color: #ffffff;
  font-family: Ubuntu;
  font-size: ${({ language }) => (language === 'DE' ? '15.7px' : '16px')};
  letter-spacing: 0;
`;

export const ErrorText = styled(CopyText)`
  background-color: #703550;
  justify-content: unset;

  svg {
    margin-right: 14px;
  }
`;

export const ButtonContainer = styled.div`
  height: 108px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
`;

export const Filename = styled.div`
  height: 23px;
  width: 100%;
  margin-top: 16px;
  color: #0272bd;
  font-family: Ubuntu;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 23px;
`;

export const FooterButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;

  div {
    margin-bottom: 13px;
  }
`;
