import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type QuestionProps = {
  isClicked?: boolean;
};

export const Question = styled.div<QuestionProps>`
  border-radius: 32px;
  background-color: #fff;
  height: 45px;
  width: 1000px;
  display: flex;
  align-items: center;
  margin: 0 auto 36px auto;
  padding: 0 22px;
  color: #525252;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 20%);
  cursor: pointer;

  .gg-arrow-right {
    margin-right: 15px;
    color: #0272bd;
    transform: ${({ isClicked }) => isClicked && `rotate(90deg)`};
    transition: transform 0.4s ease;
  }
`;
