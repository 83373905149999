import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring } from 'react-spring';
import { Formik } from 'formik';
import CopyToClipboard from 'react-copy-to-clipboard';

import { createDigestRequest } from '../../redux/selexi/actions';
import { digestSelector, pdfNameSelector, currentLanguage } from '../../redux/selexi/selectors';
import { Languages } from '../../redux/selexi/types';
import { t } from '../../locale/index';

import FileUploader from '../FileUploader';

import { FormContainer, CopyText, ButtonContainer, Filename, Span } from '../../styled/styled';

const DigestFileForm = () => {
  const dispatch = useDispatch();
  const digest = useSelector(digestSelector);
  const pdfName = useSelector(pdfNameSelector);
  const language: Languages = useSelector(currentLanguage);

  const handleChange = useCallback(
    (event) => {
      const file = event.currentTarget.files[0];
      dispatch(createDigestRequest({ file }));
    },
    [dispatch],
  );

  const styles = useSpring({
    opacity: digest ? 1 : 0,
    transform: digest ? 'translateY(0px)' : 'translateY(-10px)',
    config: { duration: 550 },
  });

  return (
    <FormContainer>
      <ButtonContainer>
        {pdfName && <Filename>{pdfName}</Filename>}
        <Formik initialValues={{ file: '' }} onSubmit={() => {}}>
          <FileUploader
            name="digest"
            fileType="application/pdf"
            description={!!pdfName ? `${t[language].changePdf}` : ''}
            callBack={handleChange}
          />
        </Formik>
      </ButtonContainer>
      {digest && (
        <CopyText style={styles}>
          <Span language={language}>{digest}</Span>
          <CopyToClipboard text={digest}>
            <div className="copy">{t[language].copy}</div>
          </CopyToClipboard>
        </CopyText>
      )}
    </FormContainer>
  );
};

export default DigestFileForm;
