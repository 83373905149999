import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  background-color: #fff;
  border: 3px solid #0272bd;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 50px;
  width: 1000px;
  font-size: 18px;
  color: #404040;
`;
