import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';

import { checkTokenRequested } from './redux/selexi/actions';
import { isTokenValid, currentLanguage } from './redux/selexi/selectors';
import { Languages } from './redux/selexi/types';
import { t } from './locale/index';

import DigestFileForm from './components/DigestFileForm';
import HashFileForm from './components/HashFileForm/index';
import Instructions from './components/Instructions/index';
import Unauthorized from './components/Unauthorized';
import Header from './components/Header';
import FAQ from './components/FAQ/index';

import { Container, Wrapper, Title, Content, Sub } from './styled/styled';
import './App.scss';

const App = () => {
  const dispatch = useDispatch();
  const tokenIsValid = useSelector(isTokenValid);
  const language: Languages = useSelector(currentLanguage);

  useEffect(() => {
    sessionStorage.removeItem('JWT');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');

    if (token) {
      const action = checkTokenRequested();
      dispatch(action);
      sessionStorage.setItem('JWT', token);
    }
  }, [dispatch]);

  const isUnauthorizedVisible = typeof tokenIsValid === 'boolean' && !tokenIsValid;
  const isLowerSectionVisible =
    typeof tokenIsValid === 'undefined' || (typeof tokenIsValid === 'boolean' && tokenIsValid);

  return (
    <div className="App">
      <Header />
      <Container>
        {isUnauthorizedVisible && <Unauthorized />}
        {tokenIsValid && (
          <Wrapper>
            <Content language={language}>
              <Title>{t[language].checkYourExam}</Title>
              <Sub>{t[language].stepOne}</Sub>
              <Sub>{parse(t[language].onlyPdf)}</Sub>
              <DigestFileForm />
            </Content>
            <Content language={language}>
              <Title>{t[language].uploadFile}</Title>
              <Sub>{t[language].stepTwo}</Sub>
              <Sub>{parse(t[language].onlyJson)}</Sub>
              <HashFileForm />
            </Content>
          </Wrapper>
        )}
      </Container>
      {isLowerSectionVisible && (
        <>
          <Instructions />
          <FAQ />
        </>
      )}
    </div>
  );
};

export default App;
