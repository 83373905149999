import { put, takeEvery, call, all, select } from 'redux-saga/effects';
import {
  createDigestSuccess,
  createHashSuccess,
  findTransactionSuccess,
  checkTokenSuccess,
  checkTokenFailed,
  alertToasterRequested,
} from './actions';
import { fileUpload, getData, postData } from './apiCall';
import { AlertToaster, Languages } from './types';
import { t } from '../../locale/index';

function* getDigestData(action: any) {
  const file = action.payload.file;
  const url = `${process.env.REACT_APP_API_ENDPOINT}/calculateDigest`;
  try {
    const response: { data: string } = yield call(() => {
      return fileUpload(url, file);
    });
    const pdfName = file.name;
    yield put(createDigestSuccess(response.data, pdfName));
  } catch (error) {
    console.log('error getDigest', error);
  }
}

function* getHashData(action: any) {
  const file = action.payload.file;
  const url = `${process.env.REACT_APP_API_ENDPOINT}/calculateHash`;

  try {
    const response: { data: string } = yield call(() => {
      return postData(url, file);
    });
    const jsonName = file.name;
    yield put(createHashSuccess(response.data, jsonName));
  } catch (error) {
    console.log('error getHash', error);
  }
}

function* findTransaction(action: any) {
  const hash = action.payload.hash;
  const url = `${process.env.REACT_APP_API_ENDPOINT}/find/${hash}`;
  const language: Languages = yield select((state) => state.main.language);

  try {
    const response: { data: { transaction_hash: string } } = yield call(() => {
      return getData(url);
    });
    yield put(findTransactionSuccess(response.data.transaction_hash));
  } catch (error) {
    console.log('error findTransaction', error);
    yield put(
      alertToasterRequested({
        message: `${t[language].noTransactionFoundMessage}`,
        id: AlertToaster.NO_TRANSACTION_FOUND,
      }),
    );
  }
}

function* checkToken() {
  const url = `${process.env.REACT_APP_API_ENDPOINT}/checkToken`;
  try {
    yield call(() => {
      return getData(url);
    });
    yield put(checkTokenSuccess());
  } catch (error) {
    console.log(error);
    yield put(checkTokenFailed());
  }
}

export function* selexiSaga() {
  yield all([takeEvery('CREATE_DIGEST_REQUEST', getDigestData)]);
  yield all([takeEvery('CREATE_HASH_REQUEST', getHashData)]);
  yield all([takeEvery('FIND_TRANSACTION_REQUEST', findTransaction)]);
  yield all([takeEvery('CHECK_TOKEN_REQUESTED', checkToken)]);
}
