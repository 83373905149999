export const t = {
  IT: {
    changeJson: `Cambia Json`,
    changePdf: `Cambia Pdf`,
    checkIntegrityBtn: `Verifica integrità`,
    checkIntegrityLabel: `3. Clicca qui per verificare l’integrità dei file caricati`,
    checkYourExam: `Controlla il tuo Esame`,
    copy: `Copia`,
    examMismatch: `Esame non corrispondente`,
    faqLabel: `Domande Frequenti`,
    faqQuestions: {
      0: {
        title: `A cosa serve il riquadro “Controlla il tuo Esame”?`,
        text: `Consente all’utente di caricare il file .pdf dell’esame sostenuto. Il sistema genera, attraverso un algoritmo crittografico, una stringa alfanumerica chiamata tecnicamente hash (o firma). Questa stringa alfanumerica corrisponde sempre e solo al tuo esame, poiché è sostanzialmente impossibile (le probabilità sono 2^256, un numero vicino a quello degli atomi che compongono l’universo) che esista un altro file con la stessa identica stringa alfanumerica.`,
      },
      1: {
        title: `A cosa serve il riquadro “Carica file”?`,
        text: `Consente all’utente di caricare il file con estensione .JSON, fornito da Selexi, che contiene al suo interno tutte le firme delle prove sostenute, compresa anche quella della tua prova (la stringa alfanumerica che hai generato dal riquadro sopra "Controlla il tuo Esame"). Se il file caricato è corretto e la tua prova non è stata modificata dopo la consegna, il sistema darà luce verde e ti permetterà di andare a controllare la transazione che certifica il tuo esame sulla blockchain.`,
      },
      2: {
        title: `Che cos’è il file .JSON?`,
        text: `All’interno del file .json ci sono una serie di hash (o firme) riferiti alle prove sostenute nella tua stessa sessione ed uno di questi fa riferimento alla tua. Se il sistema trova l’hash che hai generato nel riquadro “Controlla il tuo Esame” all’interno del file .json che hai caricato, allora la tua prova è valida e non è stata modificata.`,
      },
      3: {
        title: `Che cos’è una Blockchain?`,
        text: `Una blockchain è un registro di transazioni distribuito su internet. È uno strumento che consente di registrare digitalmente un evento e di assicurare che ciò che è presente sul registro non è stato modificato dopo il suo inserimento.`,
      },
      4: {
        title: `Che cos’è un Blockchain Explorer?`,
        text: `Un Blockchain explorer è un sito web tramite il quale è possibile verificare la corretta esecuzione delle transazioni su di una blockchain. Ogni transazione effettuata sulla blockchain sarà disponibile e visionabile sull’explorer.`,
      },
      5: {
        title: `Cosa significa l'errore "File non corretto"?`,
        text: `Significa che il file .json caricato non è quello fornito da Selexi`,
      },
      6: {
        title: `Cosa significa l'errore "Esame non corrispondente"?`,
        text: `Significa che la prova (pdf) da te caricata non trova riscontro all&#39;interno del file .json`,
      },
      7: {
        title: `Cosa significa l'errore "Non è stata trovata alcuna transazione per l'hash associato"?`,
        text: `La notarizzazione del tuo esame non è stata trovata su blockchain. Per maggiori informazioni contattaci cliccando <a href="https://www.selexi.it/it/it/contattaci.php?select_interest=home" target="_blank" rel="noopener noreferrer" style="color: #0272bd">qui</a>`,
      },
    },
    incorrectFile: `File non corretto`,
    instructions: `Istruzioni d'uso`,
    instructionOpt1: `Nel riquadro "Controlla il tuo Esame" cliccare su <i>Carica Pdf</i>`,
    instructionOpt2: `Nella finestra che si apre, seleziona il file .pdf del tuo esame e clicca su carica (passo 1)`,
    instructionOpt3: `Una volta caricato il file, il sistema mostrerà una stringa alfanumerica chiamata <i>hash</i> che lo identifica`,
    instructionOpt4: `Passa ora al riquadro sottostante “Carica file”, e clicca sul tasto <i>Carica Json</i> (passo 2)`,
    instructionOpt5: `Nella finestra che si apre, seleziona il file .json fornito da Selexi e clicca su carica`,
    instructionOpt6: `Clicca ora su “Verifica integrità” (passo 3)`,
    instructionOpt7: `Se hai caricato il file corretto, il sistema darà esito positivo circa la verifica e ti permetterà di vedere la transazione sulla blockchain`,
    instructionOpt8: `Clicca su “Guarda su blockchain” per vedere la transazione di certificazione dell’esame su blockchain (passo 4)`,
    lookOnBlockchainBtn: `Guarda su blockchain`,
    lookOnBlockchainLabel: `4. Controlla su blockchain il risultato cliccando qui`,
    notAuthenticated: `Non autenticato`,
    noTransactionFoundMessage: `Non è stata trovata alcuna transazione per l'hash associato`,
    onlyPdf: `Solo file <i>.pdf</i>`,
    onlyJson: `Solo file <i>.json</i>`,
    stepOne: `1. In questo spazio carica il file pdf contenente la tua prova`,
    stepTwo: `2. In questo spazio carica il file json contenente le firme di tutte le prove`,
    txInfo: `Nella pagina dei risultati troverai, oltre che tutte le informazioni relative alla transazione (ID, coordinate della transazione, soggetti coinvolti etc) anche lo status. Lo stato "<span style="color: green">success</span>" significa che la transazione esiste e non ha subito variazioni.`,
    uploadFile: `Carica file`,
    uploadJson: `Carica Json`,
    uploadPdf: `Carica Pdf`,
  },
  GB: {
    changeJson: `Change Json`,
    changePdf: `Change Pdf`,
    checkIntegrityBtn: `Check integrity`,
    checkIntegrityLabel: `3. Click here to verify the integrity of the uploaded files`,
    checkYourExam: `Check your Exam`,
    copy: `Copy`,
    examMismatch: `Exam mismatch`,
    faqLabel: `Frequent questions`,
    faqQuestions: {
      0: {
        title: `What is the “Check your Exam” box used for?`,
        text: `It allows the user to upload the .pdf file of the exam taken. The system generates, through a cryptographic algorithm, an alphanumeric string that is technically referred to as a hash (or signature). This alphanumeric string corresponds to your exam only, as it is essentially impossible for another file to exist with the exact same alphanumeric string (the probability is 2^256, a number close to that of the atoms that make up the universe).`,
      },
      1: {
        title: `What is the "Upload file" box used for?`,
        text: `It allows the user to upload the file with the .JSON extension, provided by Selexi, which contains all the signatures of all the tests taken, including that of your test (the alphanumeric string that you generated from the box above "Check your Exam"). If the uploaded file is correct and your test has not been modified after delivery, the system will give you a green light and will allow you to go and check the transaction that certifies your exam on the blockchain.`,
      },
      2: {
        title: `What is the .JSON file?`,
        text: `In the .json file there are a series of hashes (or signatures) referring to the tests taken in your session and one of these refers to yours. If the system finds the hash you generated in the "Check your Exam" box in the .json file you uploaded, then your test is valid and has not been modified.
`,
      },
      3: {
        title: `What is a Blockchain?`,
        text: `A blockchain is a ledger of transactions distributed over the Internet. It is a tool that is used to digitally record an event and ensure that what is on the log has not changed since it was entered.`,
      },
      4: {
        title: `What is a Blockchain Explorer?`,
        text: `A Blockchain Explorer is a website through which it is possible to verify the correct execution of transactions in a blockchain. Each transaction made in the blockchain will be available and viewable on the explorer.`,
      },
      5: {
        title: `What does the "Incorrect file" error mean?`,
        text: `It means that the uploaded .json file is not the one provided by Selexi`,
      },
      6: {
        title: `What does the "Exam mismatch" error mean?`,
        text: `It means that the test (.pdf) you uploaded was not found in the .json file`,
      },
      7: {
        title: `What does the error "No transaction was found for the associated hash" mean?`,
        text: `The notarization of your exam was not found in the blockchain. For further information, contact us by clicking <a href="https://www.selexi.it/it/it/contattaci.php?select_interest=home" target="_blank" rel="noopener noreferrer" style="color: #0272bd">here</a>`,
      },
    },
    incorrectFile: `Incorrect File`,
    instructions: `Instructions`,
    instructionOpt1: `In the "Check your Exam" box, click on <i>Upload Pdf</i>`,
    instructionOpt2: `In the window that opens, select the .pdf file of your exam and click on upload (step 1)`,
    instructionOpt3: `Once the file is uploaded, the system will show an alphanumeric string called a <i>hash</i> which is its unique identifier`,
    instructionOpt4: `Now go to the below "Upload file" box, and click on the button <i>Upload Json</i> (step 2)`,
    instructionOpt5: `In the window that opens, select the .json file provided by Selexi and click on upload`,
    instructionOpt6: `Click now on "Verify integrity" (step 3)`,
    instructionOpt7: `If you have uploaded the correct file, the system will confirm the verification and allow you to see the transaction in the blockchain`,
    instructionOpt8: `Click on “View in blockchain” to see the blockchain exam certification transaction (step 4)`,
    lookOnBlockchainBtn: `Look on blockchain`,
    lookOnBlockchainLabel: `4. Check the result in the blockchain by clicking here`,
    notAuthenticated: `Not authenticated`,
    noTransactionFoundMessage: `No transaction was found for the associated hash`,
    onlyPdf: `Only <i>.pdf</i> files`,
    onlyJson: `Only <i>.json</i> files`,
    stepOne: `1. In this section, upload the .pdf file containing your test`,
    stepTwo: `2. In this section, upload the .json file containing the signatures of all the tests`,
    txInfo: `On the results page, you will find in addition to all the information relating to the transaction (ID, details of the transaction, subjects involved, etc), the status. The "<span style="color: green">success</span>" status means that the transaction exists and has not been modified.`,
    uploadFile: `Upload file`,
    uploadJson: `Upload Json`,
    uploadPdf: `Upload Pdf`,
  },
  DE: {
    changeJson: `Json ändern`,
    changePdf: `Pdf ändern`,
    checkIntegrityBtn: `Integrität prüfen`,
    checkIntegrityLabel: `3. Klicken Sie hier, um die Integrität der hochgeladenen Dateien zu überprüfen`,
    checkYourExam: `Examen überprüfen`,
    copy: `Kopieren`,
    examMismatch: `Examen stimmt nicht überein`,
    faqLabel: `Häufige Fragen`,
    faqQuestions: {
      0: {
        title: `Wozu dient das Feld "Examen überprüfen"?`,
        text: `Es ermöglicht dem Benutzer, die .pdf-Datei der abgelegten Prüfung hochzuladen. Das System erzeugt mit Hilfe eines kryptographischen Algorithmus eine alphanumerische Zeichenfolge, die technisch als Hash (oder Signatur) bezeichnet wird. Diese alphanumerische Zeichenfolge passt nur zu Ihrer Prüfung, da es unmöglich ist, dass eine andere Datei mit der exakt gleichen alphanumerischen Zeichenfolge existiert (die Wahrscheinlichkeit beträgt 2^256, eine Zahl, die der Zahl der Atome entspricht, aus denen das Universum besteht).`,
      },
      1: {
        title: `Wozu dient das Feld "Datei hochladen"?`,
        text: `Damit können Sie die von Selexi bereitgestellte Datei mit der Erweiterung .JSON hochladen, die alle Signaturen aller abgelegten Tests enthält, einschließlich der Ihres Tests (die alphanumerische Zeichenfolge, die Sie im Feld über "Prüfen Sie Ihre Prüfung" generiert haben). Wenn die hochgeladene Datei fehlerfrei ist und Ihr Test nach der Übermittlung nicht verändert wurde, gibt das System grünes Licht und Sie können die Transaktion, die Ihre Prüfung zertifiziert, in der Blockchain überprüfen`,
      },
      2: {
        title: `Was ist eine .JSON-Datei?`,
        text: `In der .json-Datei gibt es eine Reihe von Hashes (oder Signaturen), die sich auf die in Ihrer Sitzung durchgeführten Tests beziehen, und einer davon bezieht sich auf Ihren. Wenn das System den Hash findet, den Sie im Feld "Überprüfen Sie Ihre Prüfung" in der von Ihnen hochgeladenen .json-Datei generiert haben, ist Ihr Test gültig und wurde nicht geändert`,
      },
      3: {
        title: `Was ist eine Blockchain?`,
        text: `Eine Blockchain ist ein über das Internet verteiltes Transaktionsregister. Es ist ein Werkzeug, mit dem ein Ereignis digital aufgezeichnet wird und das sicherstellt, dass sich der Inhalt des Protokolls seit seiner Erstellung nicht verändert hat`,
      },
      4: {
        title: `Was ist ein Blockchain Explorer?`,
        text: `Ein Blockchain Explorer ist eine Website, über die die korrekte Ausführung von Transaktionen in einer Blockchain überprüft werden kann. Jede in der Blockchain getätigte Transaktion ist im Explorer verfügbar und einsehbar.`,
      },
      5: {
        title: `Was bedeutet der Fehler "Falsche Datei"?`,
        text: `Er bedeutet, dass die hochgeladene .json-Datei nicht die von Selexi bereitgestellte ist`,
      },
      6: {
        title: `Was bedeutet der Fehler "Examen stimmt nicht überein"?`,
        text: `Das bedeutet, dass der Test (.pdf), den Sie hochgeladen haben, nicht in der .json-Datei gefunden wurde`,
      },
      7: {
        title: `Was bedeutet der Fehler "Für den zugehörigen Hash wurde keine Transaktion gefunden"?`,
        text: `Die Bestätigung Ihres Examens wurde nicht in der Blockchain gefunden. Klicken Sie <a href="https://www.selexi.it/it/it/contattaci.php?select_interest=home" target="_blank" rel="noopener noreferrer" style="color: #0272bd">hier</a> um weitere Informationen zu erhalten`,
      },
    },
    incorrectFile: `Falsche Datei`,
    instructions: `Anweisungen`,
    instructionOpt1: `Klicken Sie im Feld "Examen überprüfen" auf <i>PDF hochladen</i>`,
    instructionOpt2: `Wählen Sie in dem sich öffnenden Fenster die .pdf-Datei Ihrer Prüfung aus und klicken Sie auf Hochladen (Schritt 1)`,
    instructionOpt3: `Nachdem die Datei hochgeladen wurde, zeigt das System eine alphanumerische Zeichenfolge an, die als Hash bezeichnet wird und die eindeutige Kennung der Datei darstellt`,
    instructionOpt4: `Gehen Sie nun in das folgende Feld "Datei hochladen" und klicken Sie auf die Schaltfläche <i>Json hochladen</i> (Schritt 2)`,
    instructionOpt5: `Wählen Sie in dem sich öffnenden Fenster die von Selexi bereitgestellte .json-Datei aus und klicken Sie auf Upload`,
    instructionOpt6: `Klicken Sie jetzt auf "Integrität prüfen“ (Schritt 3)`,
    instructionOpt7: `Wenn Sie die richtige Datei hochgeladen haben, bestätigt das System die Verifizierung und ermöglicht es Ihnen, die Transaktion in der Blockchain zu sehen`,
    instructionOpt8: `Klicken Sie auf "In Blockchain anzeigen“, um die Transaktion zur Zertifizierung der Blockchain-Prüfung anzuzeigen (Schritt 4)`,
    lookOnBlockchainBtn: `In Blockchain anzeigen`,
    lookOnBlockchainLabel: `4. Klicken Sie auf hier, um das Ergebnis in der Blockchain zu überprüfen.`,
    notAuthenticated: `Nicht berechtigt`,
    noTransactionFoundMessage: `Für den zugehörigen Hash wurde keine Transaktion gefunden`,
    onlyPdf: `Nur <i>PDF</i> - Dateien`,
    onlyJson: `Nur <i>.Json</i> - Dateien`,
    stepOne: `1. Laden Sie in diesem Abschnitt die PDF-Datei mit Ihrem Test hoch`,
    stepTwo: `2. Laden Sie in diesem Abschnitt die .json-Datei mit den Signaturen aller Tests hoch`,
    txInfo: `Auf der Ergebnisseite finden Sie - neben allen Informationen zur Transaktion (ID, Details zur Transaktion, beteiligte Personen usw.) auch den Status. Der Status "<span style="color: green">Erfolg</span>" bedeutet, dass die Transaktion existiert und nicht geändert wurde.`,
    uploadFile: `Datei hochladen`,
    uploadJson: `Json hochladen`,
    uploadPdf: `Pdf hochladen`,
  },
};
