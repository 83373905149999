import axios from 'axios';
const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem('JWT');
    config.headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export const fileUpload = async (url: string, file: any) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        type: 'formData',
        withCredentials: true,
      },
    };
    return await axiosApiInstance.post(url, formData, config);
  } catch (e) {
    throw e;
  }
};

export const postData = async (url: string, data: any) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        type: 'formData',
        withCredentials: true,
      },
    };
    return await axiosApiInstance.post(url, data, config);
  } catch (e) {
    throw e;
  }
};

export const getData = async (url: string) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        type: 'formData',
        withCredentials: true,
      },
    };
    return await axiosApiInstance.get(url, config);
  } catch (e) {
    throw e;
  }
};
