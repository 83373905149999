import styled from 'styled-components';

export const Container = styled.header`
  position: relative;
  display: grid;
  place-items: center;
  height: 125px;
  background-color: #0f5a99;

  #flags-select {
    position: absolute;
    left: 90%;
  }

  #rfs-btn {
    color: #fff;
    border: thin solid #fff;

    &[aria-expanded='true']:after {
      border-top: 0;
      border-bottom: 5px solid #fff;
    }

    &[aria-expanded='false']:after {
      border-top: 5px solid #fff;
      border-bottom: 0;
    }
  }
`;
