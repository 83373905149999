import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring } from 'react-spring';
import CopyToClipboard from 'react-copy-to-clipboard';
import parse from 'html-react-parser';
import { Formik } from 'formik';

import FileUploader from '../FileUploader';
import { ReactComponent as AlertIcon } from '../../images/alert.svg';
import { AlertToaster, Languages } from '../../redux/selexi/types';
import { t } from '../../locale/index';

import {
  createHashRequest,
  findTransactionRequest,
  alertToasterRequested,
} from '../../redux/selexi/actions';
import {
  hashSelector,
  hashTransaction,
  jsonNameSelector,
  digestSelector,
  alertToasterSelector,
  currentLanguage,
} from '../../redux/selexi/selectors';

import { ButtonGen } from '../Button/styled';
import {
  FormContainer,
  ButtonContainer,
  CopyText,
  ErrorText,
  FooterButton,
  Filename,
  Span,
} from '../../styled/styled';
import { FlexContainer, Label } from './styled';

const readUploadedFileAsText = (inputFile: any) => {
  const temporaryFileReader = new FileReader();
  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

const HashFileForm = () => {
  const dispatch = useDispatch();
  const hash = useSelector(hashSelector);
  const transaction = useSelector(hashTransaction);
  const jsonName = useSelector(jsonNameSelector);
  const digest = useSelector(digestSelector);
  const alertToaster = useSelector(alertToasterSelector);
  const language: Languages = useSelector(currentLanguage);

  const handleChange = useCallback(
    (event) => {
      const file = event.currentTarget.files[0] as string;
      const resder = readUploadedFileAsText(file) as Promise<string>;

      resder.then((data: string) => {
        const { hashes } = JSON.parse(data);

        // Wrong .json file loaded by user
        if (!hashes) {
          dispatch(
            alertToasterRequested({
              message: t[language].incorrectFile,
              id: AlertToaster.INCORRECT_FILE,
            }),
          );
        }
        // Correct .json file but digest is not included inside the list of hashes
        else if (hashes && !hashes.includes(digest)) {
          dispatch(
            alertToasterRequested({
              message: t[language].examMismatch,
              id: AlertToaster.EXAM_MISMATCH,
            }),
          );
        }
        // Correct .json file loaded by user
        else {
          const action = createHashRequest({ hashes, file });
          dispatch(action);
        }
      });
    },
    [dispatch, digest, language],
  );

  const findHash = useCallback(() => {
    if (hash) {
      const action = findTransactionRequest(hash);
      dispatch(action);
    }
  }, [hash, dispatch]);

  const goToEtherScan = useCallback(() => {
    if (transaction) {
      window.open(`${process.env.REACT_APP_LINK_TRANSACTION}${transaction}`, '_blank');
    }
  }, [transaction]);

  const isFooterVisible = hash || transaction;

  const styles = useSpring({
    opacity: hash ? 1 : 0,
    transform: hash ? 'translateY(0px)' : 'translateY(-10px)',
    config: { duration: 550 },
  });

  const styleAlert = useSpring({
    opacity: alertToaster ? 1 : 0,
    transform: alertToaster ? 'translateY(0px)' : 'translateY(-10px)',
    config: { duration: 550 },
  });

  return (
    <>
      <FormContainer>
        <ButtonContainer>
          {jsonName && <Filename>{jsonName}</Filename>}
          <Formik initialValues={{ file: '' }} onSubmit={() => {}}>
            <FileUploader
              name="hash"
              fileType="application/json"
              description={!!jsonName ? `${t[language].changeJson}` : ''}
              callBack={handleChange}
            />
          </Formik>
        </ButtonContainer>
        {alertToaster && (
          <ErrorText style={styleAlert}>
            <AlertIcon />
            <p>{alertToaster.message}</p>
          </ErrorText>
        )}
        {hash && (
          <CopyText style={styles}>
            <Span language={language}>{hash}</Span>
            <CopyToClipboard text={hash}>
              <div className="copy">{t[language].copy}</div>
            </CopyToClipboard>
          </CopyText>
        )}
      </FormContainer>
      {isFooterVisible && (
        <FooterButton>
          {hash && (
            <FlexContainer language={language}>
              <ButtonGen onClick={findHash}>{t[language].checkIntegrityBtn}</ButtonGen>
              <Label>&nbsp; &#8592; {t[language].checkIntegrityLabel}</Label>
            </FlexContainer>
          )}
          {transaction && (
            <>
              <FlexContainer language={language}>
                <ButtonGen onClick={goToEtherScan}>{t[language].lookOnBlockchainBtn}</ButtonGen>
                <Label>&nbsp; &#8592; {t[language].lookOnBlockchainLabel}</Label>
              </FlexContainer>
              <Label>{parse(t[language].txInfo)}</Label>
            </>
          )}
        </FooterButton>
      )}
    </>
  );
};

export default HashFileForm;
