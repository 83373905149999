import { createAction } from '@reduxjs/toolkit';

export const createDigestRequest = createAction(
  'CREATE_DIGEST_REQUEST',
  function prepare({ file }: { file: any }) {
    return {
      payload: {
        file,
      },
    };
  },
);

export const createDigestSuccess = createAction(
  'CREATE_DIGEST_SUCCESS',
  function prepare(hash: string, pdfName: string) {
    return {
      payload: {
        hash,
        pdfName,
      },
    };
  },
);

export const createHashRequest = createAction(
  'CREATE_HASH_REQUEST',
  function prepare({ hashes, file }: { hashes: string[]; file: any }) {
    return {
      payload: {
        hashes,
        file,
      },
    };
  },
);

export const createHashSuccess = createAction(
  'CREATE_HASH_SUCCESS',
  function prepare(hash: string, jsonName: string) {
    return {
      payload: {
        hash,
        jsonName,
      },
    };
  },
);

export const findTransactionRequest = createAction(
  'FIND_TRANSACTION_REQUEST',
  function prepare(hash: string) {
    return {
      payload: {
        hash,
      },
    };
  },
);

export const findTransactionSuccess = createAction(
  'FIND_TRANSACTION_SUCCESS',
  function prepare(transaction: string) {
    return {
      payload: {
        transaction,
      },
    };
  },
);

export const checkTokenRequested = createAction('CHECK_TOKEN_REQUESTED', function prepare() {
  return {
    payload: {},
  };
});

export const checkTokenSuccess = createAction('CHECK_TOKEN_SUCCESS', function prepare() {
  return {
    payload: {},
  };
});

export const checkTokenFailed = createAction('CHECK_TOKEN_FAILED', function prepare() {
  return {
    payload: {},
  };
});

export const alertToasterRequested = createAction(
  'ALERT_TOASTER_REQUESTED',
  function prepare({ message, id }) {
    return {
      payload: {
        message,
        id,
      },
    };
  },
);

export const setCurrentLanguage = createAction(
  'SET_CURRENT_LANGUAGE',
  function prepare(language: string) {
    return {
      payload: {
        language,
      },
    };
  },
);
