import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import instructionsIcon from '../../images/instructions-icon.svg';
import { currentLanguage } from '../../redux/selexi/selectors';
import { Languages } from '../../redux/selexi/types';
import { t } from '../../locale/index';

import { Container, Box } from './styled';

export const Instructions = () => {
  const language: Languages = useSelector(currentLanguage);

  return (
    <Container>
      <h1>
        <img src={instructionsIcon} alt="instructions-icon" />
        {t[language].instructions}
      </h1>
      <Box language={language}>
        <p>1. {parse(t[language].instructionOpt1)}</p>
        <p>2. {parse(t[language].instructionOpt2)}</p>
        <p>3. {parse(t[language].instructionOpt3)}</p>
        <p>4. {parse(t[language].instructionOpt4)}</p>
        <p>5. {parse(t[language].instructionOpt5)}</p>
        <p>6. {parse(t[language].instructionOpt6)}</p>
        <p>7. {parse(t[language].instructionOpt7)}</p>
        <p>8. {parse(t[language].instructionOpt8)}</p>
      </Box>
    </Container>
  );
};

export default Instructions;
