import { Container, Question } from './styled';
import AnswerCard from '../AnswerCard/index';

interface Props {
  title: string;
  text: string;
  isClicked: boolean;
  onCardClick: () => void;
}

const Accordion = ({ title, text, isClicked, onCardClick }: Props) => {
  const handleClick = () => {
    onCardClick();
  };

  return (
    <Container>
      <Question onClick={handleClick} isClicked={isClicked}>
        <i className="gg-arrow-right" />
        {title}
      </Question>
      <AnswerCard text={text} isClicked={isClicked} />
    </Container>
  );
};

export default Accordion;
