import React, { useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Field, FieldAttributes } from 'formik';

import { digestSelector, currentLanguage } from '../../redux/selexi/selectors';
import { Languages } from '../../redux/selexi/types';
import { t } from '../../locale/index';

import { Button } from '../Button/styled';

interface IFileUploader {
  name: 'digest' | 'hash';
  fileType: string;
  description?: string;
  callBack: (event: React.ChangeEvent<HTMLElement>) => void;
}

const FileUploader = ({ name, fileType, description, callBack }: IFileUploader) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const digest = useSelector(digestSelector);
  const language: Languages = useSelector(currentLanguage);

  const handleClick = useCallback(() => {
    // Means that the button is disabled
    if (name === 'hash' && !digest) return;
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }
    hiddenFileInput?.current?.click();
  }, [hiddenFileInput, name, digest]);

  const label =
    fileType === 'application/pdf' ? `${t[language].uploadPdf}` : `${t[language].uploadJson}`;

  return (
    <Field name={name}>
      {({ event }: FieldAttributes<any>) => (
        <>
          <Button disabled={name === 'hash' && !digest} onClick={() => handleClick()}>
            {!!description ? description : label}
          </Button>
          <input
            type="file"
            ref={hiddenFileInput}
            accept={fileType}
            style={{ display: 'none' }}
            onChange={callBack}
          />
        </>
      )}
    </Field>
  );
};

export default FileUploader;
