export interface IGetExistingToken {
  token: string;
}

export type Languages = 'IT' | 'GB' | 'DE';

export const enum AlertToaster {
  INCORRECT_FILE = 'incorrectFile',
  EXAM_MISMATCH = 'examMismatch',
  NO_TRANSACTION_FOUND = 'noTransactionFoundMessage',
}

export type AlertToasterMessage = 'incorrectFile' | 'examMismatch' | 'noTransactionFoundMessage';
