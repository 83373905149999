import { useSelector } from 'react-redux';

import { currentLanguage } from '../../redux/selexi/selectors';
import { Languages } from '../../redux/selexi/types';
import { t } from '../../locale/index';

import { Container } from './styled';

export const Unauthorized = () => {
  const language: Languages = useSelector(currentLanguage);

  return (
    <Container>
      <h1>401</h1>
      <p>{t[language].notAuthenticated}</p>
    </Container>
  );
};

export default Unauthorized;
