import { Container } from './styled';
import { useSpring } from 'react-spring';
import parse from 'html-react-parser';

interface Props {
  text: string;
  isClicked: boolean;
}

const AnswerCard = ({ text, isClicked }: Props) => {
  const props = useSpring({
    transform: isClicked ? `translateX(0%)` : `translateX(-10%)`,
    display: isClicked ? 'initial' : 'none',
    opacity: isClicked ? 1 : 0,
  });

  return <Container style={props}>{parse(text)}</Container>;
};

export default AnswerCard;
