import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

const selectSelf = (state: RootState) => state.main;
export const isTokenValid = createSelector(selectSelf, (state) => state.isTokenValid);
export const pdfNameSelector = createSelector(selectSelf, (state) => state.pdfName);
export const jsonNameSelector = createSelector(selectSelf, (state) => state.jsonName);
export const digestSelector = createSelector(selectSelf, (state) => state.digest);
export const hashSelector = createSelector(selectSelf, (state) => state.hash);
export const hashTransaction = createSelector(selectSelf, (state) => state.transaction);
export const alertToasterSelector = createSelector(selectSelf, (state) => state.alertToaster);
export const currentLanguage = createSelector(selectSelf, (state) => state.language);
