import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Ubuntu;
  margin: 150px;
  color: #3a3a3a;

  h1 {
    font-size: 200px;
    margin-bottom: 30px;
  }

  p {
    font-size: 45px;
    border-bottom: 5px solid #0272bd;
    padding-bottom: 30px;
  }
`;
