import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';

import wizkeyLogo from '../../images/wizkey-logo.svg';
import { setCurrentLanguage } from '../../redux/selexi/actions';

import { Container } from './styled';

export const Header = () => {
  const [language, setLanguage] = useState('IT');
  const dispatch = useDispatch();

  useEffect(() => {
    const action = setCurrentLanguage(language);
    dispatch(action);
  }, [language, dispatch]);

  return (
    <Container>
      <img src={wizkeyLogo} alt="wizkey-logo" />
      <ReactFlagsSelect
        selected={language}
        onSelect={(selectedLang) => setLanguage(selectedLang)}
        countries={['GB', 'DE', 'IT']}
        customLabels={{ GB: 'English', DE: 'Deutsch', IT: 'Italiano' }}
        id="flags-select"
      />
    </Container>
  );
};

export default Header;
