import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import faqIcon from '../../images/faq-icon.png';
import { currentLanguage } from '../../redux/selexi/selectors';
import { Languages } from '../../redux/selexi/types';
import { t } from '../../locale/index';

import Accordion from '../Accordion/index';

import { Container } from './styled';

const FAQ = () => {
  const [cards, setCards] = useState<{ id: number; title: string; text: string }[]>([]);
  const [cardsClicked, setCardsClicked] = useState([
    { id: 0, isClicked: false },
    { id: 1, isClicked: false },
    { id: 2, isClicked: false },
    { id: 3, isClicked: false },
    { id: 4, isClicked: false },
    { id: 5, isClicked: false },
    { id: 6, isClicked: false },
    { id: 7, isClicked: false },
  ]);

  const language: Languages = useSelector(currentLanguage);

  useEffect(() => {
    const listOfCards: { id: number; title: string; text: string }[] = [];
    for (const card of cardsClicked) {
      type IndexRange = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
      const idx = card.id as IndexRange;

      listOfCards.push({ id: idx, ...t[language].faqQuestions[idx] });
      setCards(listOfCards);
    }
  }, [language, cardsClicked]);

  const handleOnCardClick = (cardId: number) => {
    const cardsClickedCopy = [...cardsClicked];

    for (const card of cardsClickedCopy) {
      if (card.id === cardId) {
        // We toggle the value of 'isClicked'
        card.isClicked = !card.isClicked;
      } else if (card.id !== cardId && card.isClicked) {
        // All the other card gets closed
        card.isClicked = false;
      }
    }

    setCardsClicked(cardsClickedCopy);
  };

  return (
    <Container>
      <h1>
        <img src={faqIcon} alt="faq-icon" />
        {t[language].faqLabel}
      </h1>
      {cards.map((card) => (
        <Accordion
          key={card.id}
          title={card.title}
          text={card.text}
          isClicked={cardsClicked[card.id].isClicked}
          onCardClick={() => handleOnCardClick(card.id)}
        />
      ))}
    </Container>
  );
};

export default FAQ;
