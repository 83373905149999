import styled from 'styled-components';
import { darken } from 'polished';

export const Button = styled.button`
  font-family: Ubuntu;
  color: #0272bd;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  height: 30px;
  width: 150px;
  margin: auto;
  border: 1px solid #0272bd;
  border-radius: 3px;
  background-color: #fff;
  transition: background-color 150ms linear;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:hover:enabled {
    cursor: pointer;
    background-color: ${darken(0.08, '#fff')};
  }
`;

export const ButtonGen = styled.button`
  font-family: Ubuntu;
  background-color: #0272bd;
  color: #fff;
  font-size: 1em;
  padding: 0.25em 1em;
  border: 1px solid #0272bd;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background-color: ${darken(0.08, '#0272bd')};
  }
`;
