import styled from 'styled-components';
import { Container as FAQContainer } from '../FAQ/styled';

export const Container = styled(FAQContainer)`
  padding: 50px 80px 80px 80px;

  h1 {
    color: #4a4a4a;
    margin: 0 auto 40px auto;

    img {
      filter: invert(27%) sepia(5%) saturate(0%) hue-rotate(162deg) brightness(97%) contrast(89%);
      margin-bottom: 20px;
    }
  }
`;

type BoxProps = {
  language: string;
};

export const Box = styled.div<BoxProps>`
  font-family: Ubuntu;
  font-size: 18px;
  color: #404040;
  background-color: #fff;
  border: 3px solid #0272bd;
  border-radius: 10px;
  margin: 0 auto;
  padding: 35px;
  max-width: ${({ language }) => (language === 'DE' ? '1120px' : '1020px')};

  p {
    padding: 10px;
  }
`;
