import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 50px;

  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #525252;
    width: 375px;
    padding: 10px;
    margin: 0 auto 50px auto;

    img {
      height: 80px;
      margin-bottom: 10px;
    }
  }
`;
